export async function signIn() {
    let accessToken = '';
    const response = await fetch(`https://api.integria-insurance.com/v1/auth/signin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Accept-Encoding': 'gzip, deflate, br'
        },
        body: JSON.stringify({
            "email": "info@wauwauwestend.de",
            "password": "admin@"
        })
    })
        .then(response => response.json())
        .then(response => {
          accessToken = response.access_token;

        })
        .catch(function(err) {
          console.log(err);
        })
    ;

    return accessToken;

}