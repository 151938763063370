import { ErrorMessage } from '@hookform/error-message';
export default function TextInput ({fieldName, label, register, errors, setFormData, formData, onChange, }){
    return (<div><input type="text" name='fieldName' className="form-control required" minLength="2"
                        placeholder={label} {...register(fieldName)}
                        style={{ border: errors[fieldName] ? '2px solid red' : '', marginBottom: 0 }}
                        onChange={(e) =>
                        {onChange(e);setFormData({ ...formData, [fieldName]: e.target.value })}}
                        value={formData[fieldName]? formData[fieldName]: ''}
    /><label className="title">{label}</label>
        <div style={{color: 'red'}}>
            {errors[fieldName] && errors[fieldName].type === "required" && <span>Dieses Feld ist obligatorisch.</span>}
            <ErrorMessage
                errors={errors}
                name={fieldName}
                render={({ message }) => <div>{message}</div>}
            />
        </div></div>);
}