import {signIn} from './ApiService';


const OtherInfo = ({ formData, setFormData, page, setPage, x, setX }) => {
  console.log(formData);
  const start = new Date();
  const choice2InsuranceId = {
    'choice1': '3',
    'choice2': '4',
    'choice3': '5',
    'choice4': '6'
  };
  const choice2Price = {
    'choice1': 15.90,
    'choice2': 19.90,
    'choice3': 42.90,
    'choice4': 52.90
  };


  const handleSubmit = () => {

    const insuranceId = choice2InsuranceId[formData.service_name];
    console.log('insuranceId: ' + insuranceId);

    const shopInsurancePrice = choice2Price[formData.service_name];
    console.log('shopInsurancePrice: ' + shopInsurancePrice);

    const platformData =  {
      "shopOrderId": formData.contractStart.toString(),//Startdatum der Versicherung
      "sellTimestamp": start.toISOString(),//"2000-12-03T10:15:30+01:00",
      "shopProductName": formData.animalName,//Hundename
      "shopProductId": formData.animalBirthday.toString(),//Geburtsdatum des Tieres
      "shopProductPrice": 0,
      "currency": "EUR",
      "shopInsuranceTitle": "Hundekrankenversicherung",
      "shopInsuranceId": "123",
      "shopInsurancePrice": shopInsurancePrice,
      "insuranceId": insuranceId, //3-6
      "quantity": 1,
      "buyerDetails": {
        "email": formData.email,
        "firstName": formData.firstName,
        "lastName": formData.lastName,
        "street": formData.street,
        "postCode": formData.zipCode,
        "city": formData.city,
        "country": "Deutschland"
      }

    }

    console.log(platformData)
    signIn()
        .then(response => {
          console.log(response);
          fetch(`https://api.integria-insurance.com/v1/contracts`, {
            method: 'POST', headers: {
              'Content-Type': 'application/json',
              'Accept': '*/*',
              'Accept-Encoding': 'gzip, deflate, br',
              'Authorization': 'Bearer ' + response
            }, body: JSON.stringify(platformData)
          }).then(res => {
            console.log(res);
            setPage(page + 1)
            //window.location.href = "/thankyou";
          })
              .catch(function (err) {
                console.log(err);
              })
        })

  }

  {
    return (
        <div className="multisteps-form__panel" data-animation="slideHorz">
          <div className="wizard-forms">
            <div className="inner1 pb-100 clearfix">
              <div className="form-content pera-content">
                <div className="step-inner-content">
                  <div className="multisteps-form__progress">
                    <button className="multisteps-form__progress-btn">1. Vertragsangaben</button>
                    <button className="multisteps-form__progress-btn">2. Versicherungsnehmer</button>
                    <button className="multisteps-form__progress-btn">3. Zahlungsdetails und Abschluss
                    </button>
                  </div>
                  <h2>Zahlungsdetails und Abschluss</h2>

                  <div className="step-content-area">
                    <div className="budget-area position-relative has-float-label">
                      <label className="title">Bitte wählen Sie eine Zahlungsweise</label>
                      <select name="zahlungsweise">
                        <option value="monthly" defaultValue >
                          monatlich
                        </option>
                        <option value="quarterly" >
                          vierteljährlich
                        </option>
                        <option value="half-yearly" >
                          halbjährlich
                        </option>
                        <option value="yearly" >
                          jährlich
                        </option>
                      </select>
                    </div>
                  </div>
                  <h3>Bitte wählen Sie ein Zahlungsverfahren</h3>
                  <div className="step-content-area">
                    <div className="wizard-solution-select">
                      <div className="row">
                        <div className="col-md-4">
                          <label className="option_item">
                            <input type="radio" className="hukradio" name="methodpay" value="method1" defaultChecked />
                            <span className="option_inner">
                                                        <span className="tickmark"></span>
                                                        <span className="icon">
                                                            <img src="https://www.deutsche-familienversicherung.de/typo3conf/ext/dfv_theme/Resources/Public/Images/sepa.svg" alt="" />
                                                        </span>
                                                    </span>
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label className="option_item">
                            <input type="radio" className="hukradio" name="methodpay" value="method2" />
                            <span className="option_inner">
                                                        <span className="tickmark"></span>
                                                        <span className="icon"><img src="https://www.deutsche-familienversicherung.de/typo3conf/ext/dfv_theme/Resources/Public/Images/amazon-pay.svg" alt="" /></span>
                                                    </span>
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label className="option_item">
                            <input type="radio" className="hukradio" name="methodpay" value="method3" />
                            <span className="option_inner">
                                                        <span className="tickmark"></span>
                                                        <span className="icon"><img src="https://www.deutsche-familienversicherung.de/typo3conf/ext/dfv_theme/Resources/Public/Images/paypal.svg" alt="" /></span>
                                                    </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="step-content-area content-payment">
                    <div className="content-payment1">
                      <div className="form-inner-area position-relative form-group has-float-label">
                        <input type="text" className="form-control" name="iban" placeholder="IBAN" />
                        <label className="title">IBAN</label>
                      </div>
                      <div className="form-inner-area position-relative form-group has-float-label">
                        <input type="text" className="form-control" name="namedesgeld" placeholder="Name des Geldinstitutes (wird automatisch ausgefüllt)" />
                        <label className="title">Name des Geldinstitutes (wird automatisch ausgefüllt)</label>
                      </div>
                      <div className="taxable-area">
                        <div className="row">
                          <div className="col-md-12">
                            <label>
                              <input type="checkbox" className="tax-check" value="Taxable in the US?" />
                              <span className="checkbo-box-border"></span>
                              <span className="texable-option">Ich ermächtige die Deutsche Familienversicherung, die wiederkehrenden Versicherungsbeiträge von meinem Konto einzuziehen und weise mein Kreditinstitut an, die auf mein Konto gezogenen Lastschriften einzulösen. Die Gläubiger-ID lautet DE09ZZZ00000032690 und die Mandatsreferenznummer entspricht der späteren Versicherungsschein-Nr. Ich kann innerhalb von acht Wochen ab Belastungsdatum die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.</span>
                            </label>
                          </div>
                        </div>
                        <div className="services-select-option">
                          <h3 style={{ marginBottom: "15px" }}>Weicht der Kontoinhaber vom Versicherungsnehmer ab?</h3>
                          <ul>
                            <li className="bg-white active" style={{ marginRight: "10px" }}>
                              <label>Ja
                                <input type="radio" name="weichtder" value="ja" defaultChecked />
                              </label>
                            </li>
                            <li className="bg-white">
                              <label>Nein
                                <input type="radio" name="weichtder" value="nein" />
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="content-payment2" style={{ display: "none" }}>
                      <div className="form-inner-area form-group">
                        <img src={require("../assets/img/PwA.png")} alt="" />
                      </div>
                      <p>Nutzen Sie Ihr Amazon Konto für eine schnelle Zahlungsabwicklung.</p>
                    </div>
                    <div className="content-payment3" style={{ display: "none" }}>
                      <div className="form-inner-area form-group">
                        <button style={{backgroundColor: "#0070ba", borderWidth: "0", minWidth: "250px", color: "#ffffff", height: "50px"}}>PayPal</button>
                      </div>
                      <p>Nutzen Sie Ihr Amazon Konto für eine schnelle Zahlungsabwicklung.</p>
                    </div>
                  </div>
                  <div className="step-content-area">
                    <h3>Einwilligung</h3>
                    <p style={{ fontSize: "15px" }}>Die nachfolgende Frage ist vollständig und wahrheitsgemäß zu beantworten, anderenfalls sind wir zum Rücktritt oder zur Kündigung des Versicherungsvertrages berechtigt und sie können ihren Versicherungsschutz verlieren. Bitte nehmen Sie ergänzend die Hinweise zur Verletzung der vorvertraglichen Anzeigepflicht zur Kenntnis.</p>
                    <p style={{ fontWeight: "600", fontSize: "15px" }}>Frage: Haustiere, die bei Antragsstellung an akuten oder chronischen Erkrankungen leiden, sind nicht versicherbar. Ist Ihr Haustier aktuell krank?</p>
                    <div className="wizard-checkbox-option">
                      <ul>
                        <li>
                          <label className="block-option">
                            <input type="checkbox" name="day-checkout" className="checked-checkbox" />
                            <span className="checkbox-tick"></span>
                            <p className="day-label" style={{ fontSize: "15px", lineHeight: "17px" }}>Ich habe die Hinweise zur Verletzung der vorvertraglichen Anzeigepflicht in Textform erhalten und gelesen. Die vorstehende Frage beantworte ich mit „NEIN“.</p>
                          </label>
                        </li>
                        <li>
                          <label className="block-option">
                            <input type="checkbox" name="day-checkout" className="checked-checkbox" />
                            <span className="checkbox-tick"></span>
                            <p className="day-label" style={{ fontSize: "15px", lineHeight: "17px" }}>Ich habe die Vertragsunterlagen (Informationsblatt für Versicherungsprodukte, Kundeninformation, die Versicherungsbedingungen, den Anhang zu den Versicherungsbedingungen, die Widerrufsbelehrung und Datenschutzhinweise zur Verarbeitung und Nutzung der personenbezogenen Daten sowie ggfls. der Gesundheitsdaten) zu den dort genannten Zwecken erhalten und zur Kenntnis genommen.</p>
                          </label>
                        </li>
                        <li>
                          <label className="block-option">
                            <input type="checkbox" name="day-checkout" className="checked-checkbox" />
                            <span className="checkbox-tick"></span>
                            <p className="day-label" style={{ fontSize: "15px", lineHeight: "17px" }}>Ich willige bis auf Widerruf ein, dass mich die DFV Deutsche Familienversicherung AG zukünftig über Versicherungs­angebote per Telefon und E-Mail informiert. Der Widerruf kann jederzeit formlos gegenüber der DFV Deutsche Familienversicherung AG erklärt werden. (optional)</p>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="step-content-area">
                    <p style={{ fontSize: "15px", lineHeight: "19px" }}>Bitte überprüfen Sie Ihre Angaben, bevor Sie sie absenden. Sie erhalten dann Ihre Vertragsunterlagen als Angebot. Der Versicherungsvertrag kommt dann zustande, wenn Sie den Erstbeitrag zahlen bzw. dem Einzug nicht widersprechen.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="actions">
              <ul>
                <li><span className="js-btn-prev" title="BACK" onClick={() => {
                  setPage(page - 1);
                }}><i className="fa fa-arrow-left"></i> BACK </span></li>
                <li onClick={handleSubmit}><button type="submit" title="NEXT">SUBMIT <i className="fa fa-arrow-right"></i></button></li>
              </ul>
            </div>
          </div>
        </div>
    );
  }
};

export default OtherInfo;
