import {useForm, Controller} from "react-hook-form";
import {useRef, useState} from "react";
import DatePicker from "react-datepicker";
import moment from 'moment';
import format from "date-fns/format";
import TextInput from "./TextInput";
import RadioInput from "./RadioInput";


const LocationInfo = ({ formData, setFormData, page, setPage, x, setX }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dateChosen, setDateChosen] = useState(false);
  const { register, trigger, formState: { errors }, control } = useForm({
    mode: 'onChange',
    defaultValues:
        {birthday: (formData.birthday? moment(formData.birthday, 'dd-MM-yyyy').toDate(): ''),
         salutation: formData.salutation
        }
  });
  const ref1 = useRef()
  const ref2 = useRef()

  const { ref, ...rest } = register('salutation', { required: {value: true, message: 'error'}});

  const triggerSalutation = (e, choice) => {

    const allWithClass = Array.from(
        document.querySelectorAll('div.services-select-option li')
    );

    allWithClass.forEach(element => {
      element.classList.remove('active');
    });
    e.target.classList.add('active');
    setFormData({ ...formData, salutation: choice });

    ( choice == 'Frau'? ref1: ref2).current.click()
  }

  const handleNext = async (e) => {
    let isValid = await trigger(['salutation', 'firstName', 'lastName', 'zipCode', 'city', 'street',
      'houseNumber', 'email', 'phone', 'birthday']);

    if (isValid ){
      setPage(page + 1);
    }
  }

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleChange = async(e) => {
    setIsOpen(!isOpen);
    let isValid = await trigger(['birthday']);
    if (isValid){
      setDateChosen(true);
      setFormData({ ...formData, 'birthday': format(e, "dd-MM-yyyy") });
    } else {
      setDateChosen(false);
    }
  };

  const validateField = (f) => {
    let today = new Date();
    let youngestBirthday = moment(today).subtract(18, 'years').toDate();
    let checkDate = moment(f).isBefore(youngestBirthday);
    return checkDate;
  }

  const registerFirstName = register('firstName', {required: true, minLength:
        {value: 3, message: 'Bitte mindestens 3 Buchstaben.'}});
  const registerLastName = register('lastName', {required: true, minLength:
        {value: 3, message: 'Bitte mindestens 3 Buchstaben.'}});
  const registerZipCode = register('zipCode', {required: true, pattern: {
      value: /[0-9]{5}/,
      message: 'Bitte geben Sie eine gültige Postleitzahl ein.'
    }});
  const registerCity = register('city', {required: true, minLength:
        {value: 3, message: 'Bitte mindestens 3 Buchstaben.'}});
  const registerStreet = register('street', {required: true, minLength:
        {value: 3, message: 'Bitte mindestens 3 Buchstaben.'}});
  const registerHouseNumber = register('houseNumber', {required: true});
  const registerPhone = register('phone', {required: true, pattern:
        {value: /[0-9\+()-]{8,}/, message: 'Bitte geben Sie eine valide Telefonnummer ein.'}});
  const registerEmail = register('email', {required: true, pattern:
        {value: /\S+@\S+\.\S+/, message: 'Bitte geben Sie eine valide E-Mail ein.'}});

  const { onChange, onBlur, name, value} = register('birthday',
      { required: true, validate: validateField});

  return (
      <div className="multisteps-form__panel" data-animation="slideHorz">
        <div className="wizard-forms">
          <div className="inner1 pb-100 clearfix">
            <div className="form-content pera-content">
              <div className="step-inner-content">
                {/*<span className="step-no bottom-line">2. Versicherungsnehmer</span>*/}
                <div className="multisteps-form__progress">
                  <button className="multisteps-form__progress-btn">1. Vertragsangaben</button>
                  <button className="multisteps-form__progress-btn">2. Versicherungsnehmer</button>
                  <button className="multisteps-form__progress-btn">3. Zahlungsdetails und Abschluss
                  </button>
                </div>

                <h2>Versicherungsnehmer</h2>
                <p style={{ marginTop: "20px" }} >
                  <img src={require("../assets/img/PwA.png")} alt="" />
                </p>

                <div className="services-select-option">
                  <ul>
                    <li className={formData.salutation == 'Frau'? 'active': ''} style={{ marginRight: "10px" }} id="mrs_li"
                        onClick={(e) => triggerSalutation(e, 'Frau')}><label>Frau
                      <RadioInput name='Frau' rest={rest} formData={formData} ref={ref} localRef={ref1}/></label></li>

                    <li className={formData.salutation == 'Herr'? 'active': ''} style={{ marginRight: "10px" }} id="mr_li"
                        onClick={(e) => triggerSalutation(e, 'Herr')}><label>Herr
                      <RadioInput name='Herr' rest={rest} formData={formData} ref={ref} localRef={ref2}/></label></li>

                  </ul>
                  <div style={{color: 'red'}}>
                    {errors.salutation && errors.salutation.type === "required" && <span>Dieses Feld ist obligatorisch.</span>}
                  </div>
                </div>
                <div className="form-inner-area position-relative has-float-label language-select">

                  <select name="title" {...register('title')}
                          onChange={(e) => {setFormData({ ...formData, title: e.target.value })}}
                          value={formData.title? formData.title: ''}>
                    <option value=" " defaultValue >
                      Kein Titel
                    </option>
                    <option value="Dr.">
                      Dr.
                    </option>
                    <option value="Prof.">
                      Prof.
                    </option>
                    <option value="Prof. Dr.">
                      Prof. Dr.
                    </option>
                  </select>
                  <label className="title">Titel: </label>
                </div>
                <div className="form-inner-area position-relative has-float-label">
                  <TextInput fieldName='firstName' register={register} errors={errors} label='Vorname'
                             onChange={registerFirstName.onChange} setFormData={setFormData} formData={formData}/>
                </div>
                <div className="form-inner-area position-relative has-float-label">
                  <TextInput fieldName='lastName' register={register} errors={errors} label='Nachname'
                             onChange={registerLastName.onChange} setFormData={setFormData} formData={formData}/>
                </div>
                <div className="step-content-field">
                  <div className="date-picker date datepicker2 has-float-label">
                    <input type="text" name="date3" className='form-control required'
                           placeholder='Ihr Geburtsdatum' required onClick={handleClick}  onChange={handleChange}
                           style={{ border: errors.birthday ? '2px solid red' : '' }}
                           value={dateChosen || formData.birthday? formData.birthday: ''}/>

                    <label className="title">Geburtsdatum des Tieres</label>
                    <div style={errors.birthday ? {visibility: 'visible', color: 'red'}: {visibility: 'hidden'}}>Sie sollten mindestens 18 Jahre alt sein.</div>
                  </div>
                  <div style={{color: 'red'}}>
                    {errors.birthday && errors.birthday.type === "required" && <span>Dieses Feld ist obligatorisch.</span>}
                  </div>
                  <Controller
                      control={control}
                      name="birthday"
                      value={formData.birthday? formData.birthday: ''}
                      render={({ field: { onChange, onBlur, value, ref } }) => (isOpen &&
                          <DatePicker selected={moment(new Date()).subtract(18, 'years').toDate()} onChange={(e) => {
                            onChange(e);
                            handleChange(e);
                          }}  inline showYearDropdown portalId="root-portal"
                                      dateFormatCalendar="MMMM"
                                      yearDropdownItemNumber={100}
                                      dateFormat="MM/dd/yyyy"
                                      scrollableYearDropdown
                                      placeholderText="Select a weekday"
                                      value={formData.birthday? formData.birthday: ''}

                          />
                      )}
                  />
                </div>
                <div className="form-inner-area position-relative has-float-label">
                  <TextInput fieldName='zipCode' register={register} errors={errors} label='Postleitzahl'
                             onChange={registerZipCode.onChange} setFormData={setFormData} formData={formData}/>
                </div>
                <div className="form-inner-area position-relative has-float-label">
                  <TextInput fieldName='city' register={register} errors={errors} label='Wohnort'
                             onChange={registerCity.onChange} setFormData={setFormData} formData={formData}/>
                </div>
                <div className="form-inner-area position-relative has-float-label">
                  <TextInput fieldName='street' register={register} errors={errors} label='Straße'
                             onChange={registerStreet.onChange} setFormData={setFormData} formData={formData}/>
                </div>
                <div className="form-inner-area position-relative has-float-label">
                  <TextInput fieldName='houseNumber' register={register} errors={errors} label='Hausnummer'
                             onChange={registerHouseNumber.onChange} setFormData={setFormData} formData={formData}/>
                </div>
                <div className="form-inner-area position-relative has-float-label">
                  <TextInput fieldName='phone' register={register} errors={errors} label='Telefon'
                             onChange={registerPhone.onChange} setFormData={setFormData} formData={formData}/>
                </div>
                <div className="form-inner-area position-relative has-float-label">
                  <TextInput fieldName='email' register={register} errors={errors} label='E-Mail'
                             onChange={registerEmail.onChange} setFormData={setFormData} formData={formData}/>
                </div>
              </div>
            </div>
          </div>
          <div className="actions">
            <ul>
              <li><span className="js-btn-prev" title="BACK" onClick={() => {
                setPage(page - 1);
              }}><i className="fa fa-arrow-left"></i> BACK </span></li>
              <li><span className="js-btn-next" title="NEXT" onClick={handleNext}>NEXT <i className="fa fa-arrow-right"></i></span></li>
            </ul>
          </div>
        </div>
      </div>
  );
};

export default LocationInfo;
