//import { motion } from "framer-motion";

import {within} from "@testing-library/react";
import {forEach} from "react-bootstrap/ElementChildren";

const SignUp = ({formData, setFormData, page, setPage, x, setX}) => {
    const handleChoice = function (e, choice) {
        console.log(choice)
        //set choice
        setFormData({...formData, service_name: choice});

        //deactivate previous active panels
        let panels = document.getElementsByClassName('step-box-content');
        for (const p of panels) {
            p.classList.remove('active')
        }

        //set active panel choice
        let panel = e.target.parentElement.parentElement;
        panel.classList.add("active");
    };
    /*return (
      <motion.div
        initial={{ x: x }}
        animate={{ x: 0 }}
        transition={{ duration: 1 }}
        className="card"
      >
        <div className="step-title">Sign Up</div>

        <input
          type="text"
          placeholder="Full Name"
          className="form-group"
          value={formData.fullName}
          onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
        />
        <input
          type="text"
          className="form-group"
          placeholder="Username"
          value={formData.username}
          onChange={(e) => setFormData({ ...formData, username: e.target.value })}
        />
        <input
          type="text"
          className="form-group"
          placeholder="Password"
          value={formData.password}
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
        />

        <button
          onClick={() => {
            setPage(page + 1);
            setX(1000);
          }}
        >
          Next
        </button>
      </motion.div>
    );*/
    return (
        <div className="multisteps-form__panel js-active" data-animation="slideHorz">
            {/* div 1 */}
            <div className="wizard-forms">
                <div className="inner pb-100 clearfix">
                    <div className="form-content pera-content" style={{paddingTop: '0px'}}>
                        <div className="step-inner-content">
                            <h2>Unsere Hundekrankenversicherung</h2>
                            <p>
                                als OP-Versicherung oder Vollversicherung
                            </p>
                            <div className="step-box block-plan">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <label
                                            className="step-box-content bg-white text-center relative-position active">
                                                <span className="step-box-icon">
                                                    <img src={require("../assets/img/d1.png")} alt=""/>
                                                </span>
                                            <span className="step-box-text">OP-Schutz <br></br>80</span>
                                            <p>
                                                bis zu<br></br>
                                                <span style={{fontSize: '28px'}}><strong>80 %</strong></span>
                                                <br></br>
                                                Kostenerstattung für Operationen
                                            </p>
                                            <ul className="checklist">
                                                <li><span>Voruntersuchungen</span></li>
                                                <li><span>Nachuntersuchungen</span></li>
                                                <li><span>Unterbringung in der Tierklinik</span></li>
                                                <li><span>Verbands-, Heil- und Hilfsmittel</span></li>
                                            </ul>
                                            <span className="step-box-text">15,90 €</span>
                                            <span className="service-check-option">
                                <span onClick={(event) => handleChoice(event, 'choice1')}>
                                </span>
                          </span>
                                        </label>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <label className="step-box-content bg-white text-center relative-position">
                                                <span className="step-box-icon">
                                                    <img src={require("../assets/img/d1.png")} alt=""/>
                                                </span>
                                            <span className="step-box-text">OP-Schutz <br></br>100</span>
                                            <p>
                                                bis zu<br></br>
                                                <span style={{fontSize: '28px'}}><strong>100 %</strong></span>
                                                <br></br>
                                                Kostenerstattung für Operationen
                                            </p>
                                            <ul className="checklist">
                                                <li><span>Voruntersuchungen</span></li>
                                                <li><span>Nachuntersuchungen</span></li>
                                                <li><span>Unterbringung in der Tierklinik</span></li>
                                                <li><span>Verbands-, Heil- und Hilfsmittel</span></li>
                                            </ul>
                                            <span className="step-box-text">19,90 €</span>
                                            <span className="service-check-option">
                             <span onClick={(event) => handleChoice(event, 'choice2')}>
                             </span>
                          </span>
                                        </label>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <label className="step-box-content bg-white text-center relative-position">
                                                <span className="step-box-icon">
                                                    <img src={require("../assets/img/d2.png")} alt=""/>
                                                </span>
                                            <span className="step-box-text">Vollschutz<br></br>80</span>
                                            <img
                                                src="https://www.deutsche-familienversicherung.de/fileadmin/media/images/amazon/amazon.de-stoerer-15-euro.svg"/>
                                            <p>
                                                bis zu<br></br>
                                                <span style={{fontSize: '28px'}}><strong>80 %</strong></span>
                                                <br></br> Kostenerstattung bei Krankheiten
                                            </p>
                                            <p><strong>ink. OP-Schutz (80%)</strong></p>
                                            <p>
                                                <img src={require("../assets/img/csm_FV_Logo_Blue_2807fab66b.png")}/>
                                                <br></br>
                                                Videosprechstunde
                                            </p>
                                            <p>
                                                    <span style={{fontSize: "28px"}}>
                                                        <strong>80 €</strong>
                                                    </span><br></br> Gesundheitspauschale<br></br>
                                                <span style={{fontSize: "14px"}}>(einmalig oder kalenderjährlich)</span>
                                            </p>
                                            <p>Übernahme von z.B.</p>
                                            <ul className="checklist">
                                                <li><span>Schutzimpfungen</span></li>
                                                <li><span>Wurmkur</span></li>
                                                <li><span>Zahnprophylaxe</span></li>
                                            </ul>
                                            <span className="service-check-option">
                             <span onClick={(event) => handleChoice(event, 'choice3')}>
                             </span>
                          </span>
                                        </label>
                                    </div>
                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                        <label className="step-box-content bg-white text-center relative-position">
                                                <span className="step-box-icon">
                                                    <img src={require("../assets/img/d3.png")} alt=""/>
                                                </span>
                                            <span className="step-box-text">Vollschutz<br></br>100</span>
                                            <img
                                                src="https://www.deutsche-familienversicherung.de/fileadmin/media/images/amazon/amazon.de-stoerer-15-euro.svg"/>
                                            <p>bis zu<br></br>
                                                <span
                                                    style={{fontSize: "28px"}}><strong>100 %</strong></span><br></br> Kostenerstattung
                                                bei Krankheiten&nbsp;
                                            </p>
                                            <p style={{fontSize: '24px'}}><strong>inkl. OP-Schutz (100%)</strong></p>
                                            <p><img
                                                src={require("../assets/img/csm_FV_Logo_Blue_2807fab66b.png")}/><br></br> Videosprechstunde
                                            </p>
                                            <p><span
                                                style={{fontSize: "28px"}}><strong>100 €</strong></span><br></br> Gesundheitspauschale<br></br>
                                                <span style={{fontSize: "14px"}}>(einmalig oder kalenderjährlich)</span>
                                            </p>
                                            <p>Übernahme von z.B.</p>
                                            <ul className="checklist">
                                                <li><span>Schutzimpfungen</span></li>
                                                <li><span>Wurmkur</span></li>
                                                <li><span>Zahnprophylaxe</span></li>
                                            </ul>
                                            <span className="service-check-option">
                             <span onClick={(event) => handleChoice(event, 'choice4')}>
                             </span>
                          </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /.inner */}
                <div className="actions">
                    <ul>
                        <li className="disable" aria-disabled="true">
                                <span className="js-btn-next" title="NEXT">
                                    Backward <i className="fa fa-arrow-right"/>
                                </span>
                        </li>
                        <li>
                    <span className="js-btn-next" title="NEXT" onClick={() => {
                        console.log(formData);
                        setPage(page + 1);
                        setFormData(formData);
                        setX(1000);
                    }}>
                        NEXT <i className="fa fa-arrow-right"/>
                    </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    );
};

export default SignUp;
